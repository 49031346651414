<template>
  <div v-if="returnIndividualUser">
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar ref="previewEl" :src="urlImage ? urlImage : avatar"
          :text="avatarText(returnIndividualUser.user.fullname)" size="102px" rounded />
      </template>
      <h4 class="mb-1">
        {{ returnIndividualUser.user.fullname }}
      </h4>
      <div class="d-flex flex-wrap mb-1">
        <!-- upload button -->
        <b-button variant="primary" @click="$refs.refInputEl.$el.click()">
          {{ $t("Carregar imagem") }}
        </b-button>
        <b-form-file ref="refInputEl" accept=".jpg, .png, .gif" :hidden="true" plain @change="onFileChange" />
        <!--/ upload button -->
        <b-button variant="outline-secondary" class="ml-1" @click="() => ((profileFile = 'remove'), (urlImage = 'xxx'))">
          <span class="d-none d-sm-inline">{{ $t("Remover imagem") }}</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
      <b-card-text :class="profileFile && 'text-warning'">
        {{
          profileFile && urlImage.lenght > 3
          ? $t("Imagem selecionada: ") + profileFile.name
          : $t("JPG, GIF ou PNG permitidos. Tamanho máximo de 4MB")
        }}
      </b-card-text>
    </b-media>

    <!-- User Info: Input Fields -->
    <validation-observer ref="generalUserEditForm" #default="{ invalid }">
      <b-form class="mt-2" @submit.prevent="saveChange">
        <b-row>
          <!-- Field: Username -->
          <b-col cols="12" md="4">
            <b-form-group :label="$i18n.t('Usuário')" label-for="username">
              <validation-provider #default="{ errors }" mode="eager" name="Username" vid="username"
                rules="required|min:4|max:50">
                <b-form-input id="username" v-model="username" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Full Name -->
          <b-col cols="12" md="4">
            <b-form-group :label="$i18n.t('Nome do usuário')" label-for="fullname">
              <validation-provider #default="{ errors }" mode="eager" name="Fullname" vid="fullname"
                rules="required|min:6|max:100">
                <b-form-input id="fullname" v-model="fullName" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col cols="12" md="4">
            <b-form-group :label="$i18n.t('E-mail')" label-for="email">
              <validation-provider #default="{ errors }" mode="eager" name="email" vid="email"
                rules="required|min:6|max:80|email">
                <b-form-input id="email" v-model="email" :state="errors.length > 0 ? false : null" type="email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="4">
            <b-form-group :label="$i18n.t('Status')" label-for="user-status">
              <v-select v-model="status" :options="statusOptions" :reduce="(val) => val.value" :clearable="false"
                input-id="user-status" disabled />
            </b-form-group>
          </b-col>

          <!-- Field: Phone Number -->
          <b-col cols="12" md="4">
            <b-form-group :label="$i18n.t('Contato')" label-for="Phone">
              <validation-provider #default="{ errors }" mode="eager" name="Phone" vid="phone_number" rules="max:80">
                <b-form-input id="phone_number" v-model="phoneNumber" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group :label="$i18n.t('Nova senha')" label-for="newPassword">
              <validation-provider #default="{ errors }" mode="eager" name="newPassword" vid="newPassword"
                rules="min:4|max:50">
                <b-form-input id="newPassword" v-model="newPassword" :state="errors.length > 0 ? false : null"
                  placeholder="Nova senha" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- <b-col cols="12" md="6">
            <b-row>
              <b-col cols="12">
                <div class="d-flex mt-1">
                  <b-form-checkbox v-model="enable2fa" class="custom-control-primary" name="check-button" switch />
                  <b-card-text :class="enable2fa ? 'text-success' : 'text-danger'">{{ enable2fa ? `Autenticação de dois
                    fatores habilitada` : 'Autenticação de dois fatores desabilitada' }}</b-card-text>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex mt-1">
                  <b-form-checkbox v-model="blockAccess" class="custom-control-danger" name="check-button" switch />
                  <b-card-text :class="blockAccess ? 'text-danger' : 'text-success'">{{ blockAccess ? `Acesso bloqueado!`
                    :
                    'Acesso liberado!'
                  }}</b-card-text>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex mt-1">
                  <b-form-checkbox v-model="withdrawalBlocked" class="custom-control-danger" name="check-button" switch />
                  <b-card-text :class="withdrawalBlocked ? 'text-danger' : 'text-success'">{{ withdrawalBlocked ? `Saque bloqueado!`
                    :
                    'Saque liberado!'
                  }}</b-card-text>
                </div>
              </b-col>              
            </b-row>
          </b-col> -->


          <!-- <b-col cols="12" sm="6">
            <b-row class="mt-1">
              <b-col cols="12">
                <b-alert :show="!sendEmail" variant="warning" class="mb-50 p-1">
                  {{
                    $t(`Para sua segurança, os dados da sua conta só poderão ser modificados mediante a confirmação de um
                  código enviado para seu e-mail.`)
                  }}
                  <div class="mt-1">
                    <b-button size="sm" variant="danger" @click="sendCode">{{
                      $t(`Clique aqui para receber o código no
                      e-mail`)
                    }}</b-button>
                  </div>
                </b-alert>
              </b-col>
              <b-col cols=12>
                <div class="mt-1">
                  <b-form-group :label="$t('Código de segurança')">
                    <validation-provider #default="{ errors }" mode="eager" name="securityCode" vid="securityCode">
                      <b-form-input v-model="securityCode" name="code" :placeholder="$t('Digite o código de segurança')"
                        :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </b-col> -->
        </b-row>     
        
        <b-row>
          <b-col cols="12" md="6">
            <b-row>
              
              <b-col cols="12">
                <div class="d-flex mt-1">
                  <b-form-checkbox v-model="enable2fa" class="custom-control-primary" name="check-button" switch />
                  <b-card-text :class="enable2fa ? 'text-success' : 'text-danger'">{{ enable2fa ? `Autenticação de dois
                    fatores habilitada` : 'Autenticação de dois fatores desabilitada' }}</b-card-text>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex mt-1">
                  <b-form-checkbox v-model="blockAccess" class="custom-control-danger" name="check-button" switch />
                  <b-card-text :class="blockAccess ? 'text-danger' : 'text-success'">{{ blockAccess ? `Acesso bloqueado!`
                    :
                    'Acesso liberado!'
                  }}</b-card-text>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex mt-1">
                  <b-form-checkbox v-model="withdrawalBlocked" class="custom-control-danger" name="check-button" switch />
                  <b-card-text :class="withdrawalBlocked ? 'text-danger' : 'text-success'">{{ withdrawalBlocked ? `Saque bloqueado!`
                    :
                    'Saque liberado!'
                  }}</b-card-text>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button type="submit" variant="primary" class="mt-2 mr-1" :disabled="loading">
          <div v-if="!loading">
            {{ $t("Salvar alterações") }}
          </div>
          <div v-else>
            <b-spinner small type="grow"></b-spinner>
            {{ $t("Carregando...") }}
          </div>
        </b-button>
        <b-button variant="outline-secondary" type="reset" class="mt-2" @click.prevent="loadingDataProfile">
          {{ $t("Redefinir") }}
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BFormCheckbox,
  BFormFile,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import useUsersList from "../users-list/useUsersList";
import { mapGetters, mapActions } from "vuex";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email, min } from "@/libs/validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BFormFile,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardText,
    vSelect,
    BCardBody,

    AppCollapse,
    AppCollapseItem,

    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,

    ValidationProvider,
    ValidationObserver,
    localize,
  },
  computed: {
    ...mapGetters(["returnLocale", "returnUser", "returnIndividualUser"]),
  },
  data() {
    return {
      username: null,
      fullName: null,
      email: null,
      phoneNumber: null,
      status: null,
      avatar: null,
      profileFile: null,
      urlImage: null,
      loading: false,
      blockAccess: false,
      withdrawalBlocked: false,
      enableDirectBonuses: false,
      enableUnilevelBonuses: false,
      enable2fa: false,
      blockAccess: false,
      withdrawalBlocked: false,

      newPassword: "",

      statusOptions: [],

      rolesData: [],

      titleBtnPermissonsAction: "add",

      roleFilter: 999999,
      roleOptions: [],

      required,
      email,
      min,

      sendEmail: false,
      colorAlert: "warning",
      showAlert: false,
      textAlert: "",
      securityCode: "",
    };
  },
  mounted() {
    this.statusOptions = [
      { label: this.$i18n.t("Ativo"), value: "active" },
      { label: this.$i18n.t("Inativo"), value: "inactive" },
    ];

    this.loadingDataProfile();
  },
  watch: {
    returnLocale: function (oldVal, newVal) {
      this.statusOptions = [
        { label: this.$i18n.t("Ativo"), value: "active" },
        { label: this.$i18n.t("Inativo"), value: "inactive" },
      ];
    },
    titleBtnPermissonsAction: function (oldVal, newVal) {
      if (this.titleBtnPermissonsAction == "add") {
        this.roleOptions = [
          {
            label: this.$i18n.t("Selecione:"),
            value: 999999,
          },
        ];
        this.returnUser.roles.map((el) => {
          if (el._id) {
            let idReapeat = this.roleOptions.indexOf(
              this.roleOptions.filter((item) => item.value == el._id)[0]
            );
            if (idReapeat < 0) {
              this.roleOptions.push({
                label: el.name,
                value: el._id,
              });
            }
          }
        });
      } else {
        this.roleOptions = [
          {
            label: this.$i18n.t("Selecione:"),
            value: 999999,
          },
        ];
        this.returnIndividualUser.user.roles.map((el) => {
          if (el._id) {
            let idReapeat = this.roleOptions.indexOf(
              this.roleOptions.filter((item) => item.value == el._id)[0]
            );
            if (idReapeat < 0) {
              this.roleOptions.push({
                label: el.name,
                value: el._id,
              });
            }
          }
        });
      }
    },
    "returnIndividualUser.user": function (oldVal, newVal) {
      this.fetchUserRoles();
      this.loadingDataProfile();
      this.avatar = this.returnIndividualUser.user?.avatar
        ? `${process.env.VUE_APP_API_URL}/avatar/${this.returnIndividualUser.user.avatar}`
        : null;
    },
  },
  methods: {
    ...mapActions(["addUserRole", "editUser"]),
    sendCode() {
      const data = {
        clientEmail: this.returnUser.email,
        clientName: this.returnUser.fullname,
      };
      this.$store.dispatch("sendSecurityCode", data).then((resp) => {
        this.sendEmail = true;
        this.colorAlert = "success";
        this.showAlert = true;
        this.textAlert = resp.message;
      });
    },
    loadingDataProfile() {
      this.username = this.returnIndividualUser.user.username;
      this.fullName = this.returnIndividualUser.user.fullname;
      this.email = this.returnIndividualUser.user.email;
      this.phoneNumber = this.returnIndividualUser.user.mobileNumber;
      this.status = this.returnIndividualUser.user.status;
      this.blockAccess = this.returnIndividualUser.user.blockAccess;
      this.withdrawalBlocked = this.returnIndividualUser.user.withdrawalBlocked;
      this.enableDirectBonuses = this.returnIndividualUser.user.enableDirectBonuses;
      this.enableUnilevelBonuses = this.returnIndividualUser.user.enableUnilevelBonuses;
      this.enable2fa = this.returnIndividualUser.user.authentication2fa;
      this.blockAccess = this.returnIndividualUser.user.blockAccess;
      this.withdrawalBlocked = this.returnIndividualUser.user.withdrawalBlocked;
    },
    fetchUserRoles() {
      this.rolesData = [];
      this.returnIndividualUser.user.roles.map((e, index) => {
        this.rolesData.push({
          id: 1,
          name: e.name,
          permissions: [],
        });
        e.permissions.map((el) => {
          let module =
            (el.name.split("|")[1]
              ? el.name.split("|")[1].split("_")[0] + " > "
              : "") + el.name.split("_")[1];
          let moduleRepeat = this.rolesData[index].permissions.indexOf(
            this.rolesData[index].permissions.filter(
              (item) => item.modulo == module
            )[0]
          );
          if (moduleRepeat < 0) {
            this.rolesData[index].permissions.push({
              modulo: module,
              visualizar: el.name.split("_")[2] == "view" ? true : false,
              criar: el.name.split("_")[2] == "create" ? true : false,
              editar: el.name.split("_")[2] == "edit" ? true : false,
              desativar: el.name.split("_")[2] == "remove" ? true : false,
            });
          } else {
            if (el.name.split("_")[2] == "view") {
              this.rolesData[index].permissions[moduleRepeat].visualizar = true;
            }
            if (el.name.split("_")[2] == "create") {
              this.rolesData[index].permissions[moduleRepeat].criar = true;
            }
            if (el.name.split("_")[2] == "edit") {
              this.rolesData[index].permissions[moduleRepeat].editar = true;
            }
            if (el.name.split("_")[2] == "remove") {
              this.rolesData[index].permissions[moduleRepeat].desativar = true;
            }
          }
        });

        if (this.titleBtnPermissonsAction == "add") {
          this.roleOptions = [
            {
              label: this.$i18n.t("Selecione:"),
              value: 999999,
            },
          ];
          this.returnUser.roles.map((el) => {
            if (el._id) {
              let idReapeat = this.roleOptions.indexOf(
                this.roleOptions.filter((item) => item.value == el._id)[0]
              );
              if (idReapeat < 0) {
                this.roleOptions.push({
                  label: el.name,
                  value: el._id,
                });
              }
            }
          });
        } else {
          this.roleOptions = [
            {
              label: this.$i18n.t("Selecione:"),
              value: 999999,
            },
          ];
          this.returnIndividualUser.user.roles.map((el) => {
            if (el._id) {
              let idReapeat = this.roleOptions.indexOf(
                this.roleOptions.filter((item) => item.value == el._id)[0]
              );
              if (idReapeat < 0) {
                this.roleOptions.push({
                  label: el.name,
                  value: el._id,
                });
              }
            }
          });
        }
      });
    },
    permissionsAction() {
      const data = {
        id_user: this.returnIndividualUser.user._id,
        id_role: this.roleFilter,
        method: this.titleBtnPermissonsAction,
      };
      this.addUserRole(data).then(() => {
        this.$emit("refresh-user-data");
        this.roleFilter = 999999;
      });
    },
    addPermissionsGroup() {
      this.titleBtnPermissonsAction = "add";
    },
    removePermissionsGroup() {
      this.titleBtnPermissonsAction = "remove";
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.urlImage = URL.createObjectURL(file);
      this.profileFile = file;
    },
    saveChange() {
      this.$refs.generalUserEditForm.validate().then((success) => {
        if (success) {
          const data = {
            id: this.returnIndividualUser.user._id,
            avatar: this.profileFile,
            userInfo: {
              fullname: this.fullName,
              username: this.username,
              mobileNumber: this.phoneNumber,
              email: this.email,
              newPassword: this.newPassword,
              authentication2fa: this.enable2fa,
              blockAccess: this.blockAccess,
              withdrawalBlocked: this.withdrawalBlocked,
              enableDirectBonuses: this.enableDirectBonuses,
              enableUnilevelBonuses: this.enableUnilevelBonuses,
              securityCode: this.securityCode,
              blockAccess: this.blockAccess,
              withdrawalBlocked: this.withdrawalBlocked,
            },
          };

          if (this.phoneNumber == this.returnIndividualUser.user.mobileNumber) {
            delete data.userInfo.mobileNumber;
          }

          if (this.username == this.returnIndividualUser.user.username) {
            delete data.userInfo.username;
          }

          if (this.fullName == this.returnIndividualUser.user.fullname) {
            delete data.userInfo.fullname;
          }

          if (this.email == this.returnIndividualUser.user.email) {
            delete data.userInfo.email;
          }

          if (this.newPassword == "" || this.newPassword.lenght < 4) {
            delete data.userInfo.newPassword;
          }

          this.loading = true;
          this.editUser(data)
            .then((response) => {
              this.$emit("refresh-user-data");
              this.loading = false;
              this.newPassword = "";
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "success",
                  title: this.$i18n.t("Dados alterados!"),
                  text: this.$i18n.t(
                    "As informações de perfil desse usuário foram atualizadas."
                  ),
                },
              });
            })
            .catch((error) => {
              this.$refs.generalUserEditForm.setErrors(error.errors);
              this.loading = false;
            });
        }
      });
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList();

    const permissionsData = [
      {
        module: "Admin",
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "Staff",
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: "Author",
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: "Contributor",
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "User",
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ];

    return {
      resolveUserRoleVariant,
      avatarText,
      permissionsData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.group-permissions-actions {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 4px;
}
</style>
